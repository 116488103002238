.nav-item {
    padding: 1.7vh;
    font-family: 'Roboto', sans-serif;
    color: white;
    white-space: nowrap;
    text-decoration: none;
}

.active {
    color: #152a51;
    background-color: white;
    border-radius: 20px;
}